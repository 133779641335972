<template>
    <v-card max-width="500px" class="mt-5 mx-auto" flat>
        <v-row no-gutters>
            <v-col cols="12">
                <v-img
                    @click="showDialog()"
                    src="https://wellcomefit.ch/wp-content/uploads/2023/03/Slider_Partnerangebot_Website-1-1536x361.jpg"></v-img>
            </v-col>
            <v-col cols="12">
                <v-card-title class="px-0 mx-0 pb-0 mb-0">Versicherungen für Privatpersonen</v-card-title>
            </v-col>
            <v-col cols="12">
                <v-card-text class="px-0 mx-0 pt-0 mt-0 text-justify">
                    Mut heisst am selben Strick zu ziehen. Die well come FIT AG und die Allianz beschreiten gemeinsam neue
                    Wege. Mitglieder der well come FIT Gruppe erhalten ab sofort 10% Rabatt auf Versicherungsprodukte der
                    Allianz. Alle weiteren Informationen direkt und persönlich bei unserem Allianz Kundenberater, David
                    Mora:<br /><br />
                    <v-btn @click="showDialog()" text>david.mora@allianz-suisse.ch</v-btn><br />
                    <v-btn @click="showDialog()" text>Tel. 058 357 24 19</v-btn>
                </v-card-text>
            </v-col>
            <v-col cols="12">
                <v-card-actions class="d-flex justify-center align-center"
                    ><v-btn @click="$router.push('/')" text>Zurück</v-btn
                    ><v-btn color="primary" class="white--text" @click="showDialog()"
                        >Kontakt aufnehmen</v-btn
                    ></v-card-actions
                >
            </v-col>
        </v-row>
        <v-dialog persistent v-model="dialog" width="500">
            <v-card>
                <v-card-title class="text-h5 primary text-white mb-5"> Kontaktaufnahme </v-card-title>

                <v-card-text>
                    Vielen Dank für dein Interesse an unseren exklusiven Vorteilen!<br /><br />
                    Klicke bitte auf "Bestätigen" und David Mora, unser Allianz Kundenberater, wird sich in Kürze mit dir in
                    Verbindung setzen. Vielen Dank!
                </v-card-text>

                <v-card-actions>
                    <v-btn text @click="dialog = false"> Zurück </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" class="white--text" :loading="loading" @click="enterList"> Bestätigen </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-card>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex'
import api from '@/api'
export default {
    data() {
        return {
            dialog: false,
            loading: false,
            loadingEntry: true,
            alreadyEntered: false,
        }
    },

    async created() {
        this.setNavigationbarVisibility(false)
        this.checkEntry()
    },
    beforeDestroy() {
        this.setNavigationbarVisibility(true)
    },

    methods: {
        ...mapActions('navigationStoreNotPersisted', ['setNavigationbarVisibility', 'toggleNavigationBarVisibility']),
        showDialog() {
            if (this.alreadyEntered) {
                this.$toast.success('Vielen Dank für dein Interesse, wir werden uns in Kürze bei dir melden.')
                return
            }
            this.dialog = true
        },
        async checkEntry() {
            try {
                let entry = await api.getAllianzListEntry()
                if (entry.found) {
                    this.alreadyEntered = true
                    this.$toast.success('Vielen Dank für dein Interesse, wir werden uns in Kürze bei dir melden.')
                }
            } catch (e) {
                this.$toast.error('Fehler beim Laden der Daten')
            }
            this.loadingEntry = false
        },
        async enterList() {
            if (this.alreadyEntered) {
                this.$toast.success('Vielen Dank für dein Interesse, wir werden uns in Kürze bei dir melden.')
                return
            }
            if (this.loadingEntry) return
            this.loading = true
            try {
                let status = await api.enterAllianzList()

                console.log(status)
                this.$toast.success('Vielen Dank für dein Interesse, wir werden uns in Kürze bei dir melden.')

                this.alreadyEntered = true
                this.dialog = false
            } catch (e) {
                this.$toast.error('Ein Fehler ist aufgetreten, bitte versuche es erneut')
            }

            this.loading = false

            console.log('HERE')
        },
    },
}
</script>

 
<style lang="scss" scoped>
</style>